import { useState } from "react";

export default function DisplaySearch({ fulldata, data, setData, selectedRarities, handleRarityChange }) {
  const [inputs, setInputs] = useState({});
  const [sortOption, setSortOption] = useState("rarity");

  const rarityOrder = ["common", "rare", "epic", "legendary", "limited"];
  const sortOptions = ["rarity", "alphabetical", "none"];

  const sortImages = {
    rarity: "https://app.zeqa.net/image/toggles/rarity.png",
    alphabetical: "https://app.zeqa.net/image/toggles/alpha.png",
    none: "https://app.zeqa.net/image/toggles/none.png"
  };

  function handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    const keyword = value.toLowerCase();

    if (name === "keyword") {
      const filteredResults = fulldata.filter((item) =>
        item.name.toLowerCase().includes(keyword) ||
        item.rarity.toLowerCase().includes(keyword) ||
        item.obtain.join(",").toLowerCase().includes(keyword)
      );
      setData(filteredResults);
    }

    setInputs((values) => ({ ...values, [name]: value }));
  }

  const handleSortCycle = () => {
    const currentIndex = sortOptions.indexOf(sortOption);
    const nextIndex = (currentIndex + 1) % sortOptions.length;
    const nextSortOption = sortOptions[nextIndex];
    setSortOption(nextSortOption);

    const sortedData = [...fulldata].sort((a, b) => {
      if (nextSortOption === "rarity") {
        return rarityOrder.indexOf(a.rarity) - rarityOrder.indexOf(b.rarity);
      } else if (nextSortOption === "alphabetical") {
        return a.name.localeCompare(b.name);
      } else {
        return 0;
      }
    });

    setData(sortedData);
  };

  const rarityImages = {
    common: "singles-c",
    rare: "singles-r",
    limited: "singles-lm",
    epic: "singles-e",
    legendary: "singles-l",
  };

  return (
    <div className="search_container">
      <div className="cosmetic_display_search_wrapper">
        <div className="horizontal-center">
          <div className="cosmetic_display_search">
            <input
              name="keyword"
              type="text"
              onChange={handleChange}
              value={inputs.keyword || ""}
              placeholder="Search..."
              className="search-input"
            />
          </div>
          <div className="select-checkbox">
            <div className="white-button-header">
              <button
                style={{ fontFamily: 'Minecraft Seven', padding: 0 }}
                className="white-button"
              >
                <img
                  src="https://app.zeqa.net/image/toggles/filters.png"
                  alt="Filters"
                  style={{ maxWidth: '40px', maxHeight: '34px', width: 'auto', height: 'auto' }}
                />
              </button>
              <div className="toggle-dropdown">
                {rarityOrder.map((rarity) => (
                  <div key={rarity} className="checkbox-container">
                    <input
                      type="checkbox"
                      id={`checkbox-${rarity}`}
                      value={rarity}
                      onChange={handleRarityChange}
                      checked={selectedRarities.includes(rarity)}
                    />
                    <label htmlFor={`checkbox-${rarity}`} className="checkbox">
                      <div className={`unicode-sprite ${rarityImages[rarity]}`}></div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            
          </div>
          <div className="select-checkbox">
            <div className="white-button-header">
              <button
                style={{ fontFamily: 'Minecraft Seven', padding: 0 }}
                className="white-button"
                onClick={handleSortCycle}
              >
                {}
                <img
                  src={sortImages[sortOption]}
                  alt={`Sort by ${sortOption}`}
                  style={{ maxWidth: '40px', maxHeight: '34px', width: 'auto', height: 'auto' }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
