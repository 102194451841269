import { artifactData } from "./ArtifactData";
import { capeData } from "./CapeData";
import { projectileData } from "./ProjectileData";
import { killphraseData } from "./KillphraseData";

export function getCosmeticData() {
    return {
        artifact: artifactData.reduce((accumulator, item) => {
            return { ...accumulator, [item.id]: item };
        }, {}),
        cape: capeData.reduce((accumulator, item) => {
            return { ...accumulator, [item.id]: item };
        }, {}),
        projectile: projectileData.reduce((accumulator, item) => {
            return { ...accumulator, [item.id]: item };
        }, {}),
        killphrase: killphraseData.reduce((accumulator, item) => {
            return { ...accumulator, [item.id]: item };
        }, {}),
    };
}

export function getCollection() {
    const cosmeticData = getCosmeticData();
    var result = {};
    Object.values(cosmeticData).forEach((items) => {
        Object.values(items).forEach((item) => {
            item.obtain.forEach((method) => {
                if (method in result) {
                    result[method].push(item);
                } else {
                    result[method] = [item];
                }
            });
            const rarity =
                item.rarity.charAt(0).toUpperCase() + item.rarity.slice(1);
            if (rarity in result) {
                result[rarity].push(item);
            } else {
                result[rarity] = [item];
            }
            const type = item.type.charAt(0).toUpperCase() + item.type.slice(1);
            if (type in result) {
                result[type].push(item);
            } else {
                result[type] = [item];
            }
        });
    });
    return result;
}
