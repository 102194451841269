import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

export default function NameSearch({ setPlayerItem, setTradeHistory }) {
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const playerName = query.get("player");
        if (playerName) {
            setInputs({ name: playerName });
            search(playerName);
        }
    }, [location.search]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    async function search(playerName) {
        const player = playerName || inputs.name;
        if (player) {
            setError("");
            setIsLoading(true);
            axios
                .get(`https://app.zeqa.net/api/player/item/${player}`, {
                    crossdomain: true,
                })
                .then((resp) => {
                    if (resp.status === 200 && resp.data.result.length > 0) {
                        axios
                            .get(
                                `https://app.zeqa.net/api/tradehistory/${player}`,
                                {
                                    crossdomain: true,
                                }
                            )
                            .then((resp2) => {
                                if (resp2.status === 200 && !resp2.data.err) {
                                    setPlayerItem(resp.data.result[0]);
                                    setTradeHistory(resp2.data.result);
                                    navigate(`?player=${player}`);
                                    setIsLoading(false);
                                } else {
                                    setError("Trade history not found.");
                                    setIsLoading(false);
                                }
                            })
                            .catch((e) => {
                                setError(e.message);
                                setIsLoading(false);
                            });
                    } else {
                        setError("Player not found.");
                        setPlayerItem(null);
                        setTradeHistory(null);
                        setIsLoading(false);
                    }
                })
                .catch((e) => {
                    setError(e.message);
                    setIsLoading(false);
                });
        }
    }

    return (
        <div>
            <div className="horizontal-center" style={{ textAlign: "center" }}>
                <div
                    className="cosmetic_display_search"
                    style={{
                        marginBottom: 10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <input
                        style={{ textAlign: "center" }}
                        name="name"
                        type="text"
                        onChange={handleChange}
                        value={inputs.name || ""}
                        placeholder="Enter a player's in-game name."
                    />
                </div>
                <br />
                {isLoading ? (
                    <div
                        style={{
                            color: "#ffffff",
                            marginBottom: 10,
                            fontFamily: 'Minecraft Seven',
                        }}
                    >
                        Searching...
                    </div>
                ) : (
                    <div className={`white-button-header`}>
                        <button
                            style={{ fontFamily: 'Minecraft Seven', padding: 0 }}
                            className="white-button"
                            onClick={(e) => {
                                e.preventDefault();
                                search();
                            }}
                        >
                            <img
                                src="https://app.zeqa.net/image/toggles/search.png"
                                alt="Search by rarity"
                                style={{
                                    maxWidth: '40px',
                                    maxHeight: '34px',
                                    width: 'auto',
                                    height: 'auto',
                                }}
                            />
                        </button>
                    </div>
                )}
            </div>
            <div style={{ color: "red", fontFamily: 'Minecraft Seven', textAlign: "center" }}>
                {error}
            </div>
        </div>
    );
}
