import CosmeticModel from "./CosmeticModel";

export default function CosmeticDetailLeft({ item }) {
    return (
        <div className="cosmetic_detail_left">
          <h4 style={{
                marginBottom: 30, flexDirection: 'column',
                height: 'auto',
                fontSize: 24
            }}>{item.name}</h4>
            {(item.type === "artifact" || item.type === "cape") && (
                <CosmeticModel item={item} isPreview={false} />
            )}
            {/* {item.type === "projectile" && (
                <img className={item.rarity} src={item.preview} alt="" width="300px" height="300px" />
            )} */}
            {(item.type === "projectile" || item.type === "killphrase") && (
                <h4>{item.content}</h4>
            )}
        </div>
    );
}
