import AppNavigation from "./AppNavigation";

export default function AppLayout({ children }) {
    return (
        <div className="app_layout_container">
            <AppNavigation />
            <div className="app_container">{children}</div>
        </div>
    );
}
