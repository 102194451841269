import { faSquareMinus, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import CosmeticCard from "../Cosmetic/display/CosmeticCard";

export default function CollectionRow({ collections, collection, playerItem, isOpen, onClick }) {
    const [owned, setOwned] = useState();
    const [filter, setFilter] = useState('both');

    useEffect(() => {
        setOwned(
            collections[collection].filter((item) =>
                playerItem[item.type].includes(item.id)
            ).length
        );
    }, [collections, collection, playerItem]);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredItems = collections[collection].filter(item => {
        const isOwned = playerItem[item.type].includes(item.id);
        if (filter === 'owned') return isOwned;
        if (filter === 'unowned') return !isOwned;
        return true;
    });

    return (
        <div>
            <div className="black-dropdown black-zeqa-dropdown">
                <div className={`black-dropdown-toggle black-zeqa-dropdown-toggle`}>
                    <div className={`black-select-toggle-title-wrapper`}>
                        <h6 style={{ marginBottom: 16 }}>{collection}</h6>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <h3 style={{ marginBottom: 23, marginRight: 10, fontSize: 14 }}>
                                [{owned}/{collections[collection].length}]
                            </h3>
                            <FontAwesomeIcon
                                icon={isOpen ? faSquareMinus : faSquarePlus}
                                size="2x"
                                color="white"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onClick();
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="dropdown-content-wrap zeqa-dropdown-list">
                    {isOpen && (
                        <div style={{ padding: 20 }}>
                            <div className="dropdown zeqa-dropdown">
                                <div className={`dropdown-toggle zeqa-dropdown-toggle`}>
                                    <select
                                        className="select-toggle-title-wrapper" value={filter} onChange={handleFilterChange}>
                                        <option value="both">Complete Set</option>
                                        <option value="owned">Show Owned</option>
                                        <option value="unowned">Show Unowned</option>
                                    </select></div></div>
                            <div className="cosmetic_display collection_row">
                                {filteredItems.map((item, idx) => (
                                    <CosmeticCard
                                        key={idx}
                                        item={item}
                                        nameColor={
                                            playerItem[item.type].includes(item.id)
                                                ? "#00ff73"
                                                : "gray"
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
