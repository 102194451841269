import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppLayout from "./components/layouts/AppLayout";
import Cosmetic from "./components/pages/Cosmetic";
import Home from "./components/pages/Home";
import NoPage from "./components/utils/NoPage";
import CosmeticDetail from "./components/pages/Cosmetic/detail/CosmeticDetail";

import "./css/App.css";
import "./css/Layout.css";
import "./css/Nav.css"; 
import "./css/Cosmetic.css";
import "./css/Home.css";
import "./css/Profile.css";

import ReactGA from "react-ga4";
import Profile from "./components/pages/Profile";
ReactGA.initialize([
    {
        trackingId: "G-3VGRVFWHW6",
        gaOptions: { siteSpeedSampleRate: 100 },
        gtagOptions: {},
    },
]);


export default function App() {
    useEffect(() => {
        setTimeout(() => {
            document.querySelector('.notice')?.remove();
        }, 6000);
    }, []);

    return (
        <Router>
            <div id="App">
                <AppLayout>
                    <div className="fullscreen_scroll"> 
                        <Fragment>
                            <Routes>
                                <Route exact path="/" element={<Home />} />
                                <Route path="/cosmetic">
                                    <Route
                                        exact
                                        path="/cosmetic/:type"
                                        element={<Cosmetic />}
                                    />
                                    <Route
                                        path="/cosmetic/:type/:id"
                                        element={<CosmeticDetail />}
                                    />
                                </Route>
                                 <Route path="/profile" element={<Profile />} />
                                <Route exact path="*" element={<NoPage />} />
                            </Routes>
                        </Fragment>
                    </div>
                </AppLayout>
            </div>
        </Router>
    );
}
