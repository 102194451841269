import React, { useEffect, useState } from "react";
import axios from "axios";
import CosmeticOwnerTable from "./CosmeticOwnerTable";

export function CosmeticOwner({ type, id }) {
    const [ownersResponse, setOwnersResponse] = useState();
    const [data, setData] = useState();

    useEffect(() => {
        axios
            .get(`https://app.zeqa.net/api/owners/${type}/${id}`, {
                crossdomain: true,
            })
            .then((resp) => {
                if (resp.status === 200) {
                    setOwnersResponse(resp.data.result);
                }
            });
    }, [id, type]);

    useEffect(() => {
        if (!ownersResponse) return;

        var owners = [],
            total_count = 0;
        ownersResponse.forEach((element) => {
            var items = element["owned" + type].split(",");
            var count = items.filter((x) => x === id).length;
            if (count > 0) {
                owners.push({
                    name: element.name,
                    count
                });
                total_count += count;
            }
        });
        setData({ total_count, owners });
    }, [ownersResponse, id, type]);

    if (!data) {
        return <h1>Loading...</h1>;
    }

    return (
        <div>
            <h1 style={{ marginBottom: 10, fontSize: 18 }}>Owner</h1>
            <h4 style={{ marginBottom: 10, fontSize: 14 }}>Item existed : {data.total_count}</h4>
            <CosmeticOwnerTable owners={data.owners} />
        </div>
    );
}
