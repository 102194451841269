import { Canvas } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import {
    Environment,
    OrbitControls,
    PerspectiveCamera
} from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense } from "react";

const cameraPostion = {
    cape: {
        primitive: [0, -1, 0],
        camera: [0, 0, 2],
    },
    artifact: {
        primitive: [0, -1.2, 0],
        camera: [0, 0, -3],
    },
};

export default function CosmeticModel({ item }) {
    const Model = ({ path }) => {
        const gltf = useLoader(GLTFLoader, path);
        return (
            <primitive object={gltf.scene} scale={1} position={cameraPostion[item.type].primitive} />
        );
    };

    return (
<div className="cosmetic_model_container grey"> {}
<Canvas>
        <Suspense fallback={null}>
            <Model path={item.model} />
            <PerspectiveCamera makeDefault position={cameraPostion[item.type].camera} />
            <OrbitControls autoRotate={true} />
            <Environment preset="warehouse" />
        </Suspense>
    </Canvas>
        </div >
    );
}
