import { useNavigate } from "react-router-dom";

export default function CosmeticCard({ item, nameColor }) {
    const navigate = useNavigate();

    const rarityImages = {
        common: "unicode-common",
        rare: "unicode-rare",
        limited: "unicode-limited",
        epic: "unicode-epic",
        legendary: "unicode-legendary",
    };

    return (
        <div className="gallery-slide-wrapper slider-wrapper">
            <div
                onClick={() => {
                    navigate("/cosmetic/" + item.type + "/" + item.id);
                }}
                className="grid-block-div-tall w-inline-block"
            >
                <div className="grid-tall-main-div">
                    <div className={"cosmetic_card_preview " + item.rarity}>
                        <div className="cosmetic_card_image">
                            {(item.type === "artifact" || item.type === "cape") && (
                                <img src={item.preview} width="300" height="194" alt="" loading="lazy" />
                            )}
                            {(item.type === "projectile" || item.type === "killphrase") && (
                                <h4 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '80%', margin: 'auto' }}>
                                    {item.name}
                                </h4>
                            )}
                        </div>
                    </div>
                            <div className="grid-block-text-tall">
                                <div className="cosmetic_card_detail_name" style={{ color: nameColor }}>
                                    {item.name}
                                </div>
                                <div className={`text-block-dark`}>
                                    <div className={`unicode-sprite ${rarityImages[item.rarity]}`}></div>
                                </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}
