import React, { useState, useEffect, useRef } from 'react';

export default function Home() {
    const [openDropdown, setOpenDropdown] = useState(null);
    const dropdownRefs = useRef([]);

    const handleToggle = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    const handleClickOutside = (event) => {
        if (dropdownRefs.current.every(ref => ref && !ref.contains(event.target))) {
            setOpenDropdown(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="zeqa-middle-section">
            <div className="zeqa-hero-image-wrapper">
                <div
                    style={{
                        transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                        opacity: 1,
                        transformStyle: 'preserve-3d'
                    }}
                    className="zeqa-inner-container-574px-center">
                    <div className="zeqa-logo-center">
                        <img
                            src="https://app.zeqa.net/image/zeqa_logo_transparent.webp"
                            loading="lazy"
                            sizes="(max-width: 479px) 100vw, (max-width: 767px) 83vw, 574px"
                            srcSet="https://app.zeqa.net/image/zeqa_logo_transparent-500.webp 500w, https://app.zeqa.net/image/zeqa_logo_transparent-800.webp 800w, https://app.zeqa.net/image/zeqa_logo_transparent-1080.webp 1080w, https://app.zeqa.net/image/zeqa_logo_transparent-1600.webp 1600w, https://app.zeqa.net/image/zeqa_logo_transparent.webp 2560w"
                            alt=""
                        />
                    </div>
                    <div className="zeqa-dropdown-centered zeqa-s-m-b-0">
                        <div className="dropdown zeqa-dropdown" ref={el => dropdownRefs.current[0] = el}>
                            <div
                                className={`dropdown-toggle zeqa-dropdown-toggle ${openDropdown === 0 ? 'w--open' : ''}`}
                                aria-controls="zeqa-dropdown-list-0"
                                aria-haspopup="menu"
                                aria-expanded={openDropdown === 0}
                                role="button"
                                tabIndex="0"
                                onClick={() => handleToggle(0)}
                            >
                                <div className="dropdown-toggle-title-wrapper">
                                    <div className="dropdown-toggle-title">How To Join Zeqa</div>
                                    <img loading="lazy"
                                        src="https://app.zeqa.net/image/arrowdropdown.png"
                                        alt="" className="dropdown-arrow" />
                                </div>
                            </div>
                            <nav className="dropdown-content-wrap zeqa-dropdown-list" style={{
                                height: openDropdown === 0
                                    ? window.innerWidth > 479
                                        ? '81px'
                                        : '61px'
                                    : '0px'
                            }} id="zeqa-dropdown-list-0"
                                aria-labelledby="zeqa-dropdown-toggle-0">
                                <div className="dropdown-content">
                                    <ol role="list" className="dropdown-list">
                                        <li className="dropdown-item">
                                            <div className="dropdown-paragraph">
                                                <a href="minecraft://?addExternalServer=§l§gZeqa§r|zeqa.net:19132">Click here</a> to add Zeqa to your server list.
                                            </div>
                                        </li>
                                    </ol>
                                </div>
                            </nav>
                        </div>
                        <div className="dropdown zeqa-dropdown" ref={el => dropdownRefs.current[1] = el}>
                            <div
                                className={`dropdown-toggle zeqa-dropdown-toggle ${openDropdown === 1 ? 'w--open' : ''}`}
                                aria-controls="zeqa-dropdown-list-1"
                                aria-haspopup="menu"
                                aria-expanded={openDropdown === 1}
                                role="button"
                                tabIndex="0"
                                onClick={() => handleToggle(1)}
                            >
                                <div className="dropdown-toggle-title-wrapper">
                                    <div className="dropdown-toggle-title">What Is Zeqa?</div>
                                    <img loading="lazy"
                                        src="https://app.zeqa.net/image/arrowdropdown.png"
                                        alt="" className="dropdown-arrow" />
                                </div>
                            </div>
                            <nav className="dropdown-content-wrap zeqa-dropdown-list" style={{ height: openDropdown === 1 ? '127px' : '0px' }} id="zeqa-dropdown-list-1"
                                aria-labelledby="zeqa-dropdown-toggle-1">
                                <div className="dropdown-content">
                                    <ol role="list" className="dropdown-list">
                                        <li className="dropdown-item">
                                            <footer className="dropdown-paragraph">
                                                Zeqa Network is The Largest Bedrock Practice Server.
                                            </footer>
                                        </li> <li className="dropdown-item">
                                            <footer className="dropdown-paragraph">
                                                Available on iOS, Android, Windows, Xbox, PlayStation, and Nintendo Switch
                                            </footer>
                                        </li>
                                    </ol>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
