import { useState } from "react";
import TradeHistoryTable from "./Cosmetic/detail/TradeHistoryTable";
import Collection from "./Profile/Collection";
import NameSearch from "./Profile/NameSearch";

export default function Profile() {
    const [tradeHistory, setTradeHistory] = useState();
    const [playerItem, setPlayerItem] = useState();

    return (
        <div>
            <div className="page_container">
                <div className="cosmetic_containerss">
                    <div className="cosmetic_container">
                        <h1 style={{ textAlign: "center" }}>search</h1>
                        <div className="cosmetic_display_header"></div>
                    </div>
                </div>
                <div className="page_container">
                    <div className="profile_header">
                        <div className="cosmetic_container">
                            <br />
                            <NameSearch
                                setPlayerItem={setPlayerItem}
                                setTradeHistory={setTradeHistory}
                            />
                            
                            {tradeHistory && (
                                <div className="home_container">
                                    <h1 style={{ marginBottom: 30, fontSize: 18 }}>Trade History</h1>
                                    <TradeHistoryTable tradeLog={tradeHistory} />
                                </div>
                            )}
                            {playerItem && (
                                <div className="home_container">
                                    <h1 style={{ marginBottom: 30, fontSize: 18 }}>Collection</h1>
                                    <Collection playerItem={playerItem} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
