export default function HistoryModeSelect({ mode, setMode }) {
    return (
        <ul style={{paddingInlineStart: 5}} className="cosmetic_type_select_header">
            <li
                className={
                    "cosmetic_type_select" + (mode === "all" ? " active" : "")
                }
                onClick={() => setMode("all")}
            >
                All
            </li>
            <li
                className={
                    "cosmetic_type_select" + (mode === "coin" ? " active" : "")
                }
                onClick={() => setMode("coin")}
            >
                Coins
            </li>
            <li
                className={
                    "cosmetic_type_select" + (mode === "shard" ? " active" : "")
                }
                onClick={() => setMode("shard")}
            >
                Shards
            </li>
        </ul>
    );
}
