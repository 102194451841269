export const killphraseData = [
    {
        id: "1",
        name: "Yeeted",
        type: "killphrase",
        rarity: "legendary",
        isTradable: true,
        content: "{victim} was yeeted by {killer}",
        model: null,
        preview: null,
        obtain: [],
    },
    {
        id: "2",
        name: "Kicked Out",
        type: "killphrase",
        rarity: "rare",
        isTradable: true,
        content: "{victim} was kicked out by {killer}",
        model: null,
        preview: null,
        obtain: ["Angel Rising Crate"],
    },
    {
        id: "3",
        name: "Smited",
        type: "killphrase",
        rarity: "common",
        isTradable: true,
        content: "{victim} was smited by {killer}",
        model: null,
        preview: null,
        obtain: ["Angel Rising Crate"],
    },
    {
        id: "4",
        name: "Couldn't Withstand",
        type: "killphrase",
        rarity: "rare",
        isTradable: true,
        content: "{victim} couldn't withstand {killer}",
        model: null,
        preview: null,
        obtain: ["Angel Rising Crate"],
    },
    {
        id: "5",
        name: "Shook Hand",
        type: "killphrase",
        rarity: "legendary",
        isTradable: true,
        content: "{victim} lost and shook hands with {killer}",
        model: null,
        preview: null,
        obtain: ["Angel Rising Crate", "Guarantee Legendary Crate"],
    },
    {
        id: "6",
        name: "Knocked Out",
        type: "killphrase",
        rarity: "common",
        isTradable: true,
        content: "{victim} was knocked out by {killer}",
        model: null,
        preview: null,
        obtain: ["Angel Rising Crate"],
    },
    {
        id: "7",
        name: "Stabbed 2 Death",
        type: "killphrase",
        rarity: "rare",
        isTradable: true,
        content: "{victim} was stabbed to death by {killer}",
        model: null,
        preview: null,
        obtain: ["Angel Rising Crate"],
    },
    {
        id: "8",
        name: "Grilled",
        type: "killphrase",
        rarity: "epic",
        isTradable: true,
        content: "{victim} was grilled by {killer}",
        model: null,
        preview: null,
        obtain: ["Angel Rising Crate", "Guarantee Epic Crate"],
    },
    {
        id: "9",
        name: "Too Scared",
        type: "killphrase",
        rarity: "common",
        isTradable: true,
        content: "{victim} was too scared of {killer}",
        model: null,
        preview: null,
        obtain: ["Angel Rising Crate"],
    },
    {
        id: "10",
        name: "Snapped",
        type: "killphrase",
        rarity: "common",
        isTradable: true,
        content: "{victim} was snapped by {killer}",
        model: null,
        preview: null,
        obtain: ["Angel Rising Crate"],
    },
    {
        id: "11",
        name: "Destroyed",
        type: "killphrase",
        rarity: "epic",
        isTradable: true,
        content: "{victim} got destroyed by {killer}",
        model: null,
        preview: null,
        obtain: [],
    },
    {
        id: "12",
        name: "Slapped",
        type: "killphrase",
        rarity: "epic",
        isTradable: true,
        content: "{victim} was slapped by {killer}",
        model: null,
        preview: null,
        obtain: ["Angel Rising Crate", "Guarantee Epic Crate"],
    },
    {
        id: "13",
        name: "Back2Bed",
        type: "killphrase",
        rarity: "legendary",
        isTradable: true,
        content: "{killer} sent {victim} to bed",
        model: null,
        preview: null,
        obtain: [],
    },
    {
        id: "14",
        name: "Voting",
        type: "killphrase",
        rarity: "limited",
        isTradable: false,
        content: "{killer} voted {victim} out",
        model: null,
        preview: null,
        obtain: [
            "Vip Rank",
            "Booster Rank",
            "Media Rank",
            "Media+ Rank",
            "Mvp Rank",
            "Mvp+ Rank",
        ],
    },
    {
        id: "15",
        name: "Cooked",
        type: "killphrase",
        rarity: "limited",
        isTradable: false,
        content: "{victim} was cooked by {killer}",
        model: null,
        preview: null,
        obtain: ["Mvp Rank", "Mvp+ Rank"],
    },
    {
        id: "16",
        name: "Almost Banned",
        type: "killphrase",
        rarity: "limited",
        isTradable: false,
        content: "{victim} almost got banned by {killer}",
        model: null,
        preview: null,
        obtain: ["Staff Rank"],
    },
    {
        id: "17",
        name: "Work To Death",
        type: "killphrase",
        rarity: "limited",
        isTradable: true,
        content: "{victim} was forced to work till death by {killer}",
        model: null,
        preview: null,
        obtain: [],
    },
    {
        id: "18",
        name: "Buried in Money",
        type: "killphrase",
        rarity: "limited",
        isTradable: true,
        content: "{victim} was buried in money till death by {killer}",
        model: null,
        preview: null,
        obtain: [],
    },
    {
        id: "19",
        name: "To The Moon",
        type: "killphrase",
        rarity: "limited",
        isTradable: true,
        content: "{killer} sent {victim} to the MOON",
        model: null,
        preview: null,
        obtain: [],
    },
    {
        id: "20",
        name: "Kimino Toriko",
        type: "killphrase",
        rarity: "common",
        isTradable: true,
        content: "{victim} kimino toriko {killer} at first sight",
        model: null,
        preview: null,
        obtain: ["Sun Land Crate"],
    },
    {
        id: "21",
        name: "O Mai Wa Mou Shindeiru",
        type: "killphrase",
        rarity: "epic",
        isTradable: true,
        content: "{killer} : O Mai Wa Mou Shindeiru, {victim} :Nani!",
        model: null,
        preview: null,
        obtain: ["Sun Land Crate", "Guarantee Epic Crate"],
    },
    {
        id: "22",
        name: "MUDA MUDA MUDA",
        type: "killphrase",
        rarity: "rare",
        isTradable: true,
        content: "{killer} MUDA MUDA MUDA MUDA MUDA {victim} till death",
        model: null,
        preview: null,
        obtain: ["Sun Land Crate"],
    },
    {
        id: "23",
        name: "Be My Valentine",
        type: "killphrase",
        rarity: "limited",
        isTradable: true,
        content:
            "{victim} was forced to be {killer}'s valentine until the end of time",
        model: null,
        preview: null,
        obtain: ["2022 Valentine Event Voting"],
    },
    {
        id: "24",
        name: "Rick Rolling",
        type: "killphrase",
        rarity: "common",
        isTradable: true,
        content: "{killer} rick rolled {victim} 1,000 times",
        model: null,
        preview: null,
        obtain: ["Meme Time Crate"],
    },
    {
        id: "25",
        name: "Xooper Cool",
        type: "killphrase",
        rarity: "common",
        isTradable: true,
        content: "{victim} was killed by a xooper cool player named {killer}",
        model: null,
        preview: null,
        obtain: ["Meme Time Crate"],
    },
    {
        id: "26",
        name: "Xooperior",
        type: "killphrase",
        rarity: "rare",
        isTradable: true,
        content: "{killer} is way more xooperior than {victim}",
        model: null,
        preview: null,
        obtain: ["Meme Time Crate"],
    },
    {
        id: "27",
        name: "BioHack",
        type: "killphrase",
        rarity: "rare",
        isTradable: true,
        content: "{killer} hacked and destroyed {victim}'s brains",
        model: null,
        preview: null,
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "28",
        name: "RTX3090",
        type: "killphrase",
        rarity: "common",
        isTradable: true,
        content: "{victim} was smashed by {killer} using an RTX3090",
        model: null,
        preview: null,
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "29",
        name: "Bitcoin",
        type: "killphrase",
        rarity: "rare",
        isTradable: true,
        content: "{killer} stole all of {victim}'s bitcoins",
        model: null,
        preview: null,
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "30",
        name: "Disconnect",
        type: "killphrase",
        rarity: "common",
        isTradable: true,
        content: "{killer} forced {victim} to disconnect",
        model: null,
        preview: null,
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "31",
        name: "Salute",
        type: "killphrase",
        rarity: "limited",
        isTradable: true,
        content: "{victim} salute {killer} for beating him",
        model: null,
        preview: null,
        obtain: ["Season 3 Zeqa Pass"],
    },
    {
        id: "32",
        name: "I Pet You",
        type: "killphrase",
        rarity: "limited",
        isTradable: true,
        content: "{killer} pet {victim} for his cutie",
        model: null,
        preview: null,
        obtain: ["Season 3 Zeqa Pass"],
    },
    {
        id: "33",
        name: "An Idiot Sandwich",
        type: "killphrase",
        rarity: "limited",
        isTradable: true,
        content: "{victim} told {killer} that he/she is an idiot sandwich",
        model: null,
        preview: null,
        obtain: ["Season 4 Zeqa Pass"],
    },
    {
        id: "34",
        name: "Haiyaa!",
        type: "killphrase",
        rarity: "limited",
        isTradable: true,
        content:
            "{victim} made {killer} put their leg down from the chair and say Haiyaa!",
        model: null,
        preview: null,
        obtain: ["Season 4 Zeqa Pass"],
    },
    {
        id: "35",
        name: "Before you were born",
        type: "killphrase",
        rarity: "limited",
        isTradable: true,
        content: "{killer} beating countless people before {victim} were born",
        model: null,
        preview: null,
        obtain: ["Season 4 Login Reward"],
    },
    {
        id: "36",
        name: "Infinity & Beyond",
        type: "killphrase",
        rarity: "common",
        isTradable: true,
        content: "{killer} send {victim} to the infinity and beyond",
        model: null,
        preview: null,
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "37",
        name: "Event Horizon",
        type: "killphrase",
        rarity: "rare",
        isTradable: true,
        content: "{killer} has left {victim} alone in the event horizon",
        model: null,
        preview: null,
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "38",
        name: "Go Wild",
        type: "killphrase",
        rarity: "limited",
        isTradable: true,
        content: "{killer} go wild and send {victim} to die",
        model: null,
        preview: null,
        obtain: ["Season 5 Zeqa Pass"],
    },
    {
        id: "39",
        name: "Extinct",
        type: "killphrase",
        rarity: "limited",
        isTradable: true,
        content: "{killer} made {victim} become extinct",
        model: null,
        preview: null,
        obtain: ["Season 5 Zeqa Pass"],
    },
    {
        id: "40",
        name: "Lure",
        type: "killphrase",
        rarity: "limited",
        isTradable: true,
        content: "{killer} lure {victim} to death",
        model: null,
        preview: null,
        obtain: ["Season 5 Login Reward"],
    },
    {
        "id": "41",
        "name": "Lightning Strike",
        "type": "killphrase",
        "rarity": "limited",
        "isTradable": true,
        "content": "{killer} 7summon a lightning stike directly on {victim}",
        "model": "",
        "preview": "",
        "obtain": ["Season 6 Zeqa Pass"]
    },
    {
        "id": "42",
        "name": "Freezing",
        "type": "killphrase",
        "rarity": "limited",
        "isTradable": true,
        "content": "{killer} 7freeze {victim} 7's death body in his refrigerator",
        "model": "",
        "preview": "",
        "obtain": ["Season 6 Zeqa Pass"]
    },
    {
        "id": "43",
        "name": "Buried Alive",
        "type": "killphrase",
        "rarity": "limited",
        "isTradable": true,
        "content": "{victim} 7was buried alive by {killer}",
        "model": "",
        "preview": "",
        "obtain": ["Season 7 Zeqa Pass"]
    },
    {
        "id": "44",
        "name": "Burning",
        "type": "killphrase",
        "rarity": "limited",
        "isTradable": true,
        "content": "{killer} 7burn {victim} 7with hell flame to death",
        "model": "",
        "preview": "",
        "obtain": ["Season 6 Login Reward"]
    }
];
