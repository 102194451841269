import { TableSortLabel } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";

const heads = [
    { name: "name", displayName: "Player" },
    { name: "count", displayName: "Owned" },
];

export default function CosmeticOwnerTable({ owners }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderBy, setOrderBy] = useState();
    const [orderAsc, setOrderAsc] = useState();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSort = (event, name) => {
        event.preventDefault();
        setOrderAsc(orderBy === name ? !orderAsc : true);
        setOrderBy(name);
    };

    return (
        <div className="trade_history_table_wrapper">
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 300 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {heads.map((col, idx) => (
                                    <TableCell
                                        key={col.name}
                                        align={"center"}
                                        style={{ minWidth: 80 }}
                                        sortDirection={
                                            orderBy === col.name
                                                ? orderAsc
                                                    ? "asc"
                                                    : "desc"
                                                : false
                                        }
                                    >
                                        <TableSortLabel
                                            active={orderBy === col.name}
                                            direction={
                                                orderBy === col.name
                                                    ? orderAsc
                                                        ? "asc"
                                                        : "desc"
                                                    : "asc"
                                            }
                                            onClick={(event) =>
                                                handleSort(event, col.name)
                                            }
                                        >
                                            {col.displayName}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {owners
                                .sort((a, b) => {
                                    var greater = 0;
                                    if (a[orderBy] < b[orderBy]) greater = -1;
                                    else if (a[orderBy] > b[orderBy])
                                        greater = 1;
                                    return orderAsc ? greater : -greater;
                                })
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((item, idx) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={idx}
                                        >
                                            <TableCell
                                                align="center"
                                                style={{ minWidth: 80 }}
                                            >
                                                {item.name}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{ minWidth: 80 }}
                                            >
                                                {item.count}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100, 500]}
                    component="div"
                    count={owners.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
