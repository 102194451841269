import ArtifactDisplay from "./Cosmetic/ArtifactDisplay";
import CapeDisplay from "./Cosmetic/CapeDisplay";
import ProjectileDisplay from "./Cosmetic/ProjectileDisplay";
import KillphraseDisplay from "./Cosmetic/KillphraseDisplay";
import NoPage from "../utils/NoPage";
import { useParams } from "react-router-dom";

export default function Cosmetic() {
    const { type } = useParams();

    if (!["artifact", "cape", "projectile", "killphrase"].includes(type)) {
        return <NoPage />;
    }

    return (
        <div className="page_container">
            <h1 style={{ marginBottom: 20 }}>
                
            </h1> 
            {type === "artifact" && <ArtifactDisplay />}
            {type === "cape" && <CapeDisplay />}
            {type === "projectile" && <ProjectileDisplay />}
            {type === "killphrase" && <KillphraseDisplay />}
        </div>
    );
}
