export default function CosmeticDetailRight({ item }) {
    return (
        <div className="cosmetic_detail_right">
            <h4 style={{ marginBottom: 60, marginTop: 10, fontSize: 24 }}>Information</h4>
            <h5 style={{ marginBottom: 30, fontSize: 18 }}>Name : {item.name}</h5>
            <h5 style={{ marginBottom: 30, fontSize: 18 }}>Type : {item.type}</h5>
            <h5 style={{ marginBottom: 30, fontSize: 18 }}>Rarity : {item.rarity}</h5>
            <h5 style={{ marginBottom: 30, fontSize: 18 }}>Tradable : {item.isTradable ? "yes" : "no"}</h5>
            <h5>
                Obtain :{" "}
                {item.obtain.length ? item.obtain.join(", ") : "no data"}
            </h5>
        </div>
    );
}
