import React, { useEffect, useState } from "react";
import axios from "axios";
import Chart from "react-apexcharts";
import HistoryModeSelect from "./HistoryModeSelect";
import TradeHistoryTable from "./TradeHistoryTable";

export default function TradeHistory({ type, id }) {
    const [tradeLogResponse, setTradeLogResponse] = useState();
    const [tradeLog, setTradeLog] = useState();
    const [tradeChartSeries, setTradeChartSeries] = useState();

    const [mode, setMode] = useState("all");

    const chartOption = {
        chart: {
            id: "tradeHistory",
        },
        xaxis: {
            type: "datetime",
        },
        yaxis: {
            logarithmic: true,
        },
    };

    useEffect(() => {
        axios
            .get(`https://app.zeqa.net/api/tradelog/${type}/${id}`, {
                crossdomain: true,
            })
            .then((resp) => {
                if (resp.status === 200) {
                    setTradeLogResponse(resp.data.result);
                }
            });
    }, [id, type]);

    useEffect(() => {
        if (!tradeLogResponse) return;

        var series = [];
        tradeLogResponse.forEach((element) => {
            if (element.from_item === mode && element.from_item_detail >= 10) {
                series.push({
                    x: element.timestamp * 1000,
                    y: element.from_item_detail,
                });
            } else if (
                element.to_item === mode &&
                element.to_item_detail >= 10
            ) {
                series.push({
                    x: element.timestamp * 1000,
                    y: element.to_item_detail,
                });
            }
        });
        series.sort((a, b) => a.x - b.x);
        setTradeChartSeries(series);

        var logs = tradeLogResponse;
        logs.sort((a, b) => -(a.timestamp - b.timestamp));
        setTradeLog(logs);
    }, [tradeLogResponse, id, type, mode]);

    if (!tradeLog && !tradeChartSeries) {
        return <h1>Loading...</h1>;
    }

    return (
        <div> 
             <h1 style={{ marginBottom: 20, fontSize: 18 }}>Trading History</h1>
            <HistoryModeSelect  mode={mode} setMode={setMode} />
            {mode === "all" ? (
                tradeLog.length === 0 ? (
                    <h4>no data</h4>
                ) : (
                    <TradeHistoryTable tradeLog={tradeLog} />
                )
            ) : tradeChartSeries.length === 0 ? (
                <h4>no data</h4>
            ) : (
                <Chart
                    options={chartOption}
                    series={[
                        {
                            name: mode,
                            data: tradeChartSeries,
                        },
                    ]}
                    type="line"
                    width="100%"
                    height="300px"
                />
            )}
        </div>
    );
}
