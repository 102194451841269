import { useParams } from "react-router-dom";
import { getCosmeticData } from "../../../utils/data/CosmeticData";
import NoPage from "../../../utils/NoPage";
import CosmeticDetailLeft from "./CosmeticDetailLeft";
import CosmeticDetailRight from "./CosmeticDetailRight";
import { CosmeticOwner } from "./CosmeticOwner";
import TradeHistory from "./TradeHistory";

export default function CosmeticDetail() {
    const { type, id } = useParams();
    const cosmeticData = getCosmeticData();

    if (!(type in cosmeticData && id in cosmeticData[type])) {
        return <NoPage />;
    }

    const item = cosmeticData[type][id];

    return (
        <div className="page_container"  style={{
            width: '80%',             minWidth: '40%', 

            margin: '0 auto',
          }}><br></br>
            <div className="cosmetic_container cosmetic_detail_container">
                <CosmeticDetailLeft item={item} />
                <CosmeticDetailRight item={item} />
            </div><br></br><br></br>
            <div className="cosmetic_container">
                <CosmeticOwner type={type} id={id} />
            </div><br></br>
            <div className="cosmetic_container">
                <TradeHistory type={type} id={id} />
            </div><br></br><br></br><br></br>
        </div>
    );
}
