export default function NoPage() {
    return (
        <section class="_404-stack">
            <div class="_404-container zeqa-container">
                <div
                    class="_404-div">
                    <div class="_404-tile-div">
                        <h1 style={{ marginTop: 0, marginLeft: 10, marginRight: 10 }} class="_404-heading">404 Error.</h1>
                        <div class="_404-text loading-animation">Redirecting In </div>
                    </div>
                </div>
            </div>
            <script>
                function redirect() {
                    setTimeout(function () {
                        window.location.href = "https://app.zeqa.net/";
                    }, 5000)
                }
            </script>
        </section>
    );
}
