export const projectileData = [
    {
        id: "1",
        name: "Explode",
        type: "projectile",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Enchanted Money Crate"],
    },
    {
        id: "2",
        name: "Splash",
        type: "projectile",
        rarity: "common",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Enchanted Money Crate"],
    },
    {
        id: "3",
        name: "Water Splash",
        type: "projectile",
        rarity: "common",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Enchanted Money Crate"],
    },
    {
        id: "4",
        name: "Critical",
        type: "projectile",
        rarity: "common",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Enchanted Money Crate"],
    },
    {
        id: "5",
        name: "Smoke",
        type: "projectile",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: null,
        preview: null,
        obtain: [
            "Vip Rank",
            "Booster Rank",
            "Media Rank",
            "Media+ Rank",
            "Mvp Rank",
            "Mvp+ Rank",
        ],
    },
    {
        id: "6",
        name: "Mob Spell",
        type: "projectile",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: null,
        preview: null,
        obtain: ["Mvp Rank", "Mvp+ Rank"],
    },
    {
        id: "7",
        name: "Flame",
        type: "projectile",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Enchanted Money Crate", "Guarantee Epic Crate"],
    },
    {
        id: "8",
        name: "Lava",
        type: "projectile",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Enchanted Money Crate"],
    },
    {
        id: "9",
        name: "Redstone",
        type: "projectile",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Enchanted Money Crate"],
    },
    {
        id: "10",
        name: "Heart",
        type: "projectile",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Enchanted Money Crate", "Guarantee Epic Crate"],
    },
    {
        id: "11",
        name: "Enchantment Table",
        type: "projectile",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Enchanted Money Crate", "Guarantee Legendary Crate"],
    },
    {
        id: "12",
        name: "Villager Happy",
        type: "projectile",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: [],
    },
    {
        id: "13",
        name: "Villager Angry",
        type: "projectile",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: null,
        preview: null,
        obtain: ["Staff Rank"],
    },
    {
        id: "14",
        name: "R-Explode",
        type: "projectile",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Reverse Projectile Crate"],
    },
    {
        id: "15",
        name: "R-Splash",
        type: "projectile",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Reverse Projectile Crate"],
    },
    {
        id: "16",
        name: "R-Water Splash",
        type: "projectile",
        rarity: "common",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Reverse Projectile Crate"],
    },
    {
        id: "17",
        name: "R-Critical",
        type: "projectile",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: [],
    },
    {
        id: "18",
        name: "R-Smoke",
        type: "projectile",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: null,
        preview: null,
        obtain: [],
    },
    {
        id: "19",
        name: "R-Mob Spell",
        type: "projectile",
        rarity: "epic",
        isTradable: false,
        content: null,
        model: null,
        preview: null,
        obtain: ["Reverse Projectile Crate"],
    },
    {
        id: "20",
        name: "R-Flame",
        type: "projectile",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Reverse Projectile Crate"],
    },
    {
        id: "21",
        name: "R-Lava",
        type: "projectile",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: [],
    },
    {
        id: "22",
        name: "R-Redstone",
        type: "projectile",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: [],
    },
    {
        id: "23",
        name: "R-Heart",
        type: "projectile",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Reverse Projectile Crate"],
    },
    {
        id: "24",
        name: "R-Enchantment Table",
        type: "projectile",
        rarity: "common",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Reverse Projectile Crate"],
    },
    {
        id: "25",
        name: "R-Villager Happy",
        type: "projectile",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: null,
        preview: null,
        obtain: ["Reverse Projectile Crate"],
    },
    {
        id: "26",
        name: "R-Villager Angry",
        type: "projectile",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: null,
        preview: null,
        obtain: [],
    },
];
