import { useEffect, useState } from "react";
import { getCollection } from "../../utils/data/CosmeticData";
import CollectionRow from "./CollectionRow";

export default function Collection({ playerItem }) {
    const collections = getCollection();
    const [items, setItems] = useState();
    const [openCollection, setOpenCollection] = useState(null);

    useEffect(() => {
        if (playerItem) {
            let newItems = {};
            newItems["artifact"] = playerItem.ownedartifact.split(",");
            newItems["cape"] = playerItem.ownedcape.split(",");
            newItems["killphrase"] = playerItem.ownedkillphrase.split(",");
            newItems["projectile"] = playerItem.ownedprojectile.split(",");
            setItems(newItems);
        }
    }, [playerItem]);

    const handleCollectionClick = (collection) => {
        setOpenCollection(openCollection === collection ? null : collection);
    };

    if (collections && items) {
        const priorityOrder = ["Artifact", "Cape", "Killphrase", "Projectile"];

        const sortedCollections = Object.keys(collections).sort((a, b) => {
            if (priorityOrder.includes(a) && priorityOrder.includes(b)) {
                return priorityOrder.indexOf(a) - priorityOrder.indexOf(b);
            }
            if (priorityOrder.includes(a)) return -1;
            if (priorityOrder.includes(b)) return 1;
            return a.localeCompare(b);
        });

        return sortedCollections.map((collection, idx) => (
            <CollectionRow
                key={idx}
                collections={collections}
                collection={collection}
                playerItem={items}
                isOpen={openCollection === collection}
                onClick={() => handleCollectionClick(collection)}
            />
        ));
    }

    return null;
}
